import React, { createContext, useMemo, useState } from "react"
import { CONSTANT } from "../utils/Constant"
//define context
const AppContext = createContext()
const { Provider } = AppContext

const AppProvider = ({ children, authData }) => {
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [alertMsg, setAlertMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: null,
    modalObject: null,
  })
  const [authenticate] = useState(() => {
    return authData && authData?.access_token
  })

  const certProcessInitData = {
    signManually: CONSTANT.DEFAULT_MANUALLY,
    signDigital: CONSTANT.DEFAULT_DIGITAL,
    clientProfile: {},
    certificateList: [],
  }

  const [certProcessData, setCertProcessData] = useState(certProcessInitData)
  const [sesionUnique, setSesionUnique] = useState()

  const resetErrorMsg = () => {
    setErrorMsg(null)
  }

  const resetSuccessMsg = () => {
    setSuccessMsg(null)
  }

  const resetAlertMsg = () => {
    setAlertMsg(null)
  }

  const values = useMemo(
    () => ({
      authenticate,
      certProcessData,
      isLoading,
      errorMsg,
      alertMsg,
      successMsg,
      modalData,
      sesionUnique,
      setLoading,
      resetErrorMsg,
      setErrorMsg,
      resetSuccessMsg,
      setSuccessMsg,
      setCertProcessData,
      setModalData,
      setAlertMsg,
      resetAlertMsg,
      setSesionUnique,
    }),
    // eslint-disable-next-line
    [
      authenticate,
      certProcessData,
      isLoading,
      errorMsg,
      alertMsg,
      successMsg,
      modalData,
      sesionUnique,
    ]
  )

  return <Provider value={values}>{children}</Provider>
}

export { AppContext, AppProvider }
