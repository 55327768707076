import React from "react"
import ReactDOM from "react-dom/client"
import { appRoutes } from "./routers/AppRoutes"
import "bootstrap/dist/css/bootstrap.css"
import reportWebVitals from "./reportWebVitals"
import { RouterProvider } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<RouterProvider router={appRoutes} />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
