export const CONSTANT = {
  /** DISPATCHER PREFIX */
  DISPATCHER_SECURITY_PREFIX: "/security",
  DISPATCHER_USER_PREFIX: "/users",
  DISPATCHER_MAINTENANCE_PREFIX: "/maintenance",
  DISPATCHER_PAYMENT_PREFIX: "/payment",

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  DANGER_MSG: 2,
  WARNING_MSG: 3,

  /** DEFAULT YES AND NOT */
  DEFAULT_MANUALLY: true,
  DEFAULT_DIGITAL: false,

  /** PORTAL */
  PORTAL_DD: "DD",
  PORTAL_GL: "GL",
  PORTAL_OP: "OP",
  PORTAL_SM: "SM",

  /** IDENTIFICATION TYPE VALUE  */
  IDENTIFICATION_NATIONAL_VALUE: "N",
  IDENTIFICATION_FOREIGN_VALUE: "E",

  /** PHONETYPE */
  CODPHONE: 506,

  /** LANGUAGE */
  DEFAULT_LANGUAGE_ES: "ES",
  DEFAULT_LANGUAGE_EN: "EN",

  /** INVOICE_TYPE */
  INVOICE_TYPE_1: "CCF",
  INVOICE_TYPE_2: "CVCF",
  INVOICE_TYPE_3: "CG",
  INVOICE_TYPE_4: "CRE",

  /** DEPARTMENT */
  DEPARTMENT_CON_VALUE: "CON",
  DEPARTMENT_OPE_VALUE: "OPE",
  DEPARTMENT_SEC_VALUE: "SEC",
  DEPARTMENT_ALL_VALUE: "ALL",

  /** MANAGEMENT STATUS */
  MANAGEMENT_STATUS_SLC: "SLC",
  MANAGEMENT_STATUS_PRC: "PRC",
  MANAGEMENT_STATUS_FIN: "FIN",
  MANAGEMENT_STATUS_FAC: "FAC",
  MANAGEMENT_STATUS_DEL: "DEL",

  /** TAX */
  TAX_VALUE: 0.18,

  /** CURRENCY */
  CURRENCY_SYMBOL_DOM: " DOP",
  CURRENCY_CODE: "DOP",
  CURRENCY_CODE2: "USD",

  /** CARD TYPE EXP */
  VISA_CARDTYPE_EXP: /^(?:4[0-9]{2}?)$/,
  MASTERCARD_CARDTYPE_EXP: /^(?:5[1-5][0-9]{3})$/,
  AMERICANEXPRESS_CARDTYPE_EXP: /^(?:3[47][0-9]{3})$/,

  /** SOCIALNETWORKTYPE */
  SOCIALTYPE_FACEBOOK: "FACEBOOK",
  SOCIALTYPE_INSTAGRAM: "INSTAGRAM",
  SOCIALTYPE_TWITTER: "TWITTER",
  SOCIALTYPE_LINKEDIN: "LINKEDIN",
  SOCIALTYPE_YOUTUBE: "YOUTUBE",

  /** HEADER TYPE */
  HEADER_TYPE: {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  },
  HEADER_TYPE2: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  AUTH_GRANTTYPE: "password",
}
