import React, { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
  Navigate,
} from "react-router-dom";
import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";

/** Template */
const HomeLayout = lazy(() => import("../components/template/HomeLayout"));
const Layout = lazy(() => import("../components/template/Layout"));

/** Login page */
const Login = lazy(() => import("../pages/home/login/Login"));
const RecoveryPassword = lazy(() =>
  import("../pages/home/recoveryPassword/RecoveryPassword")
);
const ForgotPassword = lazy(() =>
  import("../pages/home/forgotPassword/ForgotPassword")
);
const ActiveRegister = lazy(() =>
  import("../pages/home/activeRegister/ActiveRegister")
);

/** Main page */
const CertifiedProcess = lazy(() => import("../pages/manage/CertifiedProcess"));

/** Utils page */
const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route element={<HomeLayout />}>
        <Route path="/login" element={<Login />} />
        <Route exact path="/recover-password" element={<RecoveryPassword />} />
        <Route
          exact
          path="/forgot-password/:portal/:code/:email"
          element={<ForgotPassword />}
        />
        <Route
          exact
          path="/active-register/:portal/:code/:email"
          element={<ActiveRegister />}
        />
      </Route>

      <Route path="/management" element={<Layout />}>
        <Route path="process/:dni" element={<CertifiedProcess />} />
      </Route>
      <Route exact path="/notfound" element={<NotFound />} />
      {/* NOTA: Quemado para temas de pruebas con cedula fija de D0000118460252 */}
      {/* <Route
        path='*'
        element={<Navigate to='/management/process/D0000118460252' replace />}
      /> */}
      <Route path="/" element={<Navigate to="/login" replace />} />
      {/* <Route path="/config.js" element={<Navigate to="/notfound" replace />} /> */}
      <Route path="*" element={<Navigate to="/notfound" replace />} />
      {/* <Route path="/*conf" element={<Navigate to="/notfound" replace />} /> */}
    </Route>
  )
);
