import { CONSTANT } from "./Constant";

export const URL = {
  /*  APPLOGIN_REQUEST: CONSTANT.DISPATCHER_SECURITY_PREFIX + "/oauth/token", */
  APPLOGIN_REQUEST:
    CONSTANT.DISPATCHER_SECURITY_PREFIX +
    "/oauth/token?grant_type=client_credentials",
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordForm",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",

  /** maintenance urls */
  CERTCATEGORY_REQUEST:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + "/certificate-categories/",
  REQUEST_MANAGEMENT_LIST: (page, size) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/managements/${page}/${size}`,
  REQUEST_MANAGEMENT: (id) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/managements/${id ? id : ""}`,
  CERTIFICATE_REQUEST_ACTIVE_USER: (sesionUniqueRif, sesionUniqueToken) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/parval/status?rif=${sesionUniqueRif}&token=${sesionUniqueToken}`,
  GET_SESSION_REQUEST: (dni) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/session/create?rif=${dni}`,

  /** payment urls */
  PAYERAUTHENTICATION_REQUEST:
    CONSTANT.DISPATCHER_PAYMENT_PREFIX + "/process/paymentauth",
  PAYERAUTHENTICATIONENROLLMENT_REQUEST:
    CONSTANT.DISPATCHER_PAYMENT_PREFIX + "/process/paymentauthenrollment",
  PAYMENTCOMPLETE_REQUEST:
    CONSTANT.DISPATCHER_PAYMENT_PREFIX + "/process/complete",
  PAYMENTGETTRANSACTION_REQUEST:
    CONSTANT.DISPATCHER_PAYMENT_PREFIX + "/process/paymentauthresponsebank",
  PAYMENTTRANSACTIONRESULT_REQUEST:
    CONSTANT.DISPATCHER_PAYMENT_PREFIX + "/process/paymentauthresult",
  PAYMENTTRANSACTIONIDMANAGEMENT:
    CONSTANT.DISPATCHER_PAYMENT_PREFIX + "/process/paymentnotification",

  /** PARVAL EXTERNAL API */
  CLIENTACCOUNTS_REQUEST: "/user/cuentas",
  CLIENTPROFILE_REQUEST: "/user/perfil",
};
