export const properties = {
  /** General */

  /** footer */
  com_parval_label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  com_parval_label_page_404_title: "Oops!",
  com_parval_label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  com_parval_label_loading_title: "Cargando",

  /** Home */
  com_parval_label_home_title: "Parval",

  /** General error labels in process */
  com_parval_label_request_connection: "Falla en solicitud al servidor",
  com_parval_label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  com_parval_label_request_badrequest:
    "Falla en parametros dentro de la solicitud al servidor",

  /** General label */
  com_parval_label_yes: "Si",
  com_parval_label_not: "No",
  com_parval_label_add_subtitle: "Ingrese los datos indicados",
  com_parval_label_update_subtitle: "Modifique los datos indicados",
  com_parval_label_code: "Código",
  com_parval_label_codetfa: "Código de validación",
  com_parval_label_phone: "Teléfono",
  com_parval_label_url: "Url",
  com_parval_label_category: "Categoría",
  com_parval_label_name: "Nombre",
  com_parval_label_lastname: "Apellido",
  com_parval_label_state: "Estado",
  com_parval_label_role: "Rol",
  com_parval_label_createat: "Fecha de creación",
  com_parval_label_initdate: "Fecha inicio",
  com_parval_label_specificdate: "Fecha Especifica",
  com_parval_label_enddate: "Fecha final",
  com_parval_label_email: "Correo electrónico",
  com_parval_label_password: "Contraseña",
  com_parval_label_passwordrepeat: "Repita contraseña",
  com_parval_label_id: "ID",
  com_parval_label_description: "Descripción",
  com_parval_label_price: "Precio",
  com_parval_label_price2: "Precio:",
  com_parval_label_subtotal: "SubTotal",
  com_parval_label_total: "Total",
  com_parval_label_taxes: "Impuesto",
  com_parval_label_tax: "ITBIS (18%)",
  com_parval_label_department: "Departamento",
  com_parval_label_generationtime: "Tiempo generación",
  com_parval_label_comment: "Comentario",
  com_parval_label_free: "Gratis",
  com_parval_label_zero: 0,
  com_parval_label_select: "Seleccione",
  com_parval_label_select_other: "Otro",
  com_parval_label_not_apply: "No aplica",
  com_parval_label_time: "Duración ",
  com_parval_label_observacion: "Observación",
  com_parval_label_terms: "Aceptar términos y condiciones",
  com_parval_label_note: "Nota",
  com_parval_label_department_con_title: "Contabilidad",
  com_parval_label_department_ope_title: "Operaciones",
  com_parval_label_department_sec_title: "Servicio al cliente",
  com_parval_label_department_all_title: "Todos",
  com_parval_label_cardnumber: "Número tarjeta",
  com_parval_label_exp: "Fecha expiración",
  com_parval_label_cvv: "Cvv",
  com_parval_label_TypeCard: "Tipo de tarjeta",
  com_parval_label_cardname: "Nombre titular",
  com_parval_label_certificate: "Certificado",
  com_parval_label_account: "Cuenta",
  com_parval_label_to: "Dirigido a",
  com_parval_label_invoicetype: "Tipo de factura",
  com_parval_label_language: "Idioma",
  com_parval_label_not_apply_title: "No aplica",
  com_parval_label_card_title: "TARJETA",
  /** General error labels in forms */
  com_parval_label_field_required: "Campo requerido",
  com_parval_label_field_format: "Formato invalido",
  com_parval_label_field_maxlenght: (lenght) =>
    `Longitud máxima permitida (${lenght})`,
  com_parval_label_field_minlenght: (lenght) =>
    `Longitud minima permitida (${lenght})`,

  /** General button labels */
  com_parval_label_button_accept_title: "Aceptar",
  com_parval_label_button_ok_title: "Ok",
  com_parval_label_button_cancel_title: "Cancelar",
  com_parval_label_button_save_title: "Registrar",
  com_parval_label_button_update_title: "Editar",
  com_parval_label_button_delete_title: "Eliminar",
  com_parval_label_button_back_title: "Atras",
  com_parval_label_button_back2_title: "Volver",
  com_parval_label_button_add_title: "Agregar",
  com_parval_label_button_search_title: "Buscar",
  com_parval_label_button_clear_title: "Limpiar",
  com_parval_label_button_login_title: "Iniciar Sesión",
  com_parval_label_button_close_title: "Cerrar",
  com_parval_label_button_view_title: "Ver",
  com_parval_label_button_exit_title: "Salir",
  com_parval_label_button_forgotpass_title: "Recuperar contraseña",
  com_parval_label_button_updatepass_title: "Actualizar contraseña",
  com_parval_label_button_payment_title: "Procesar pago",
  /** General Modal */
  com_parval_label_modal_title: "Terminos y Condiciones",
  /** login */
  com_parval_label_loginPortal_title: "Portal",
  com_parval_label_loginCliente_title: "Ejecutivo",
  com_parval_label_login_forget_password_title: "¿Olvidaste tu contraseña?",
  com_parval_label_login_portal_select_title: "Seleccione portal",
  com_parval_label_login_portal_dd_title: "Due Diligence",
  com_parval_label_login_portal_gl_title: "Gestiones en Línea",
  com_parval_label_login_portal_op_title: "Operaciones",
  com_parval_label_login_portal_sm_title: "Simulador Mutuos",
  /** login success*/
  com_parval_label_login_twofactor_success:
    "Código de validación enviado al correo",
  /** login error*/
  com_parval_label_login_twofactor_error:
    "Error en proceso de enviar código de validación",
  com_parval_label_login_error: "Correo o contraseña inválida",
  com_parval_label_login_error2:
    "Usuario no tiene permiso de loguearse en este sitio",
  com_parval_label_login_error3: "El usuario no está activo",
  com_parval_label_login_general_error:
    "Error en proceso de ingresar al portal administrativo",
  com_parval_label_login_logout_error: "Error en proceso de cerrar sesion",

  /** RecoveryPassword */
  com_parval_label_recoverypass_title: "Recuperar Contraseña",
  com_parval_label_recoverypass_back_title: "Regresar a login",
  /** ForgotPassword success*/
  com_parval_label_recoverypass_general_sucess:
    "El correo de recuperación a sido enviado",
  /** ForgotPassword error*/
  com_parval_label_recoverypass_general_error:
    "Error en proceso de recuperar contraseña",

  /** ForgotPassword */
  com_parval_label_forgotpass_title: "Cambiar Contraseña",
  /** ForgotPassword success*/
  com_parval_label_forgotpass_general_sucess:
    "El cambio de contraseña se completo",
  /** ForgotPassword error*/
  om_parval_label_forgotpass_different_password_error:
    "Las contraseñas no coinciden",
  com_parval_label_forgotpass_general_error:
    "Error en proceso de cambiar contraseña",

  /** ActiveRegister */
  com_parval_label_activeregister_title: "Activación Usuario",
  /** ActiveRegister success*/
  com_parval_label_activeregister_general_sucess:
    "La activación del usuario se completó",
  com_parval_label_activeregister_general_sucess2:
    "La activación del usuario se completó correctamente.",
  /** ActiveRegister error*/
  com_parval_label_activeregister_general_error:
    "Error en proceso de activar usuario",

  /** Wizard */
  com_parval_label_template_title: "GESTIONES EN LÍNEA",

  /** Wizard */
  com_parval_label_wizard_step1_title: "Certificaciones",
  com_parval_label_wizard_step2_title: "Cuentas",
  com_parval_label_wizard_step3_title: "Confirmación de compra",
  com_parval_label_wizard_step4_title: "Resumen",
  com_parval_label_wizard_button_next_title: "SIGUIENTE",
  com_parval_label_wizard_button_back_title: "ATRAS",
  com_parval_label_wizard_button_print_title: "IMPRIMIR",
  com_parval_label_wizard_button_finalize_title: "FINALIZAR",
  com_parval_label_wizard_button_payment_title: "PAGAR",
  com_parval_label_wizard_button_ok_title: "OK",
  com_parval_label_wizard_actions_title: "Acciones",

  /** Wizard STEP1 Certificaciones*/
  com_parval_label_step1_category_title: "Categoría de certificados",
  com_parval_label_step1_category_select_title: "Seleccione una categoría",
  com_parval_label_step1_cost_free: "Gratis",
  com_parval_label_step1_department_title: "Departamento:",
  com_parval_label_step1_signature_title: "Firma manuscrita",
  com_parval_label_step1_signature_physical_title: "Certificación en físico",
  com_parval_label_step1_signature_notphysical_title:
    "Certificación electrónica con firma digital",
  com_parval_label_certificatebox_generationtime_desc_format: (d) =>
    `Tiempo de generación: ${d} día${d > 1 ? "s" : ""} hábil${
      d > 1 ? "es" : ""
    } `,
  com_parval_label_generationtime_desc_format2: (d) =>
    `${d} día${d > 1 ? "s" : ""} hábil${d > 1 ? "es" : ""} `,
  /** Wizard STEP1 Errors*/
  com_parval_label_step1_nextvalidate_message:
    "Debe seleccionar al menos un certificado para continuar",

  /** Wizard STEP2 Account*/
  com_parval_label_step2_add_button_title: "Agregar certificado",
  com_parval_label_step2_delete_button_title: "Eliminar certificado",
  com_parval_label_step2_clientdata_title: "Datos del cliente",
  com_parval_label_step2_dni_title: "Cédula:",
  com_parval_label_step2_name2_title: "Nombre:",
  com_parval_label_step2_certificate_title: "Certificado",
  com_parval_label_step2_language_title: "Idioma",
  com_parval_label_step2_description_title: "Dirigido a",
  com_parval_label_step2_name_title: "Nombre",
  com_parval_label_step2_account_title: "Cuenta",
  com_parval_label_step2_invoicetype_title: "Tipo de factura",
  com_parval_label_step2_account_select_title: "Seleccione una cuenta",
  com_parval_label_step2_invoice_select_title: "Seleccione tipo de factura",
  com_parval_label_step2_invoicetype1_title: "Comprobantes de consumidor final",
  com_parval_label_step2_invoicetype2_title:
    "Comprobante válido para crédito fiscal",
  com_parval_label_step2_invoicetype3_title: "Comprobantes gubernamentales",
  com_parval_label_step2_invoicetype4_title:
    "Comprobantes para regímenes especiales",
  com_parval_label_step2_note1_title: "Comprobantes de consumidor final: ",
  com_parval_label_step2_note1_desc:
    "Personas Físicas Locales, Extranjeras y Personas Jurídicas Extrajeras",
  com_parval_label_step2_note2_title:
    "Comprobante válido para crédito fiscal: ",
  com_parval_label_step2_note2_desc:
    "Personas Físicas registradas en la DGII y Personas Jurídicas Locales",

  /** Wizard STEP3*/
  com_parval_label_step3_title: "Proceso de pago",
  com_parval_label_step3_note_desc:
    "La Certificación será emitida a favor del cliente al cual se le expide la certificación, indistintamente el tipo de comprobante elegido.",
  com_parval_label_step3_payment_title: "Datos de pago",

  /** Wizard STEP4*/
  com_parval_label_step4_clientcode_title: "Código cliente:",
  com_parval_label_step4_date_title: "Fecha:",
  com_parval_label_step4_ordernumber_title: "Número de orden:",
  com_parval_label_step4_paymenttype_title: "Forma de pago:",
  com_parval_label_step4_summary_title: "Resumen ",
  com_parval_label_step4_orderdata_title: "Datos de la orden:",
  com_parval_label_step4_clientdata_title: "Datos del cliente:",
  com_parval_label_step4_success_message_title:
    "La orden fue procesado correctamente",
  /** APP LOGIN MSG*/
  com_parval_label_applogin_error:
    "Error en proceso de inicio de sesión de aplicación",

  /** CERTIFICATE CATEGORY MSG*/
  com_parval_label_certificatecategory_error:
    "Error en proceso de obtener el listado de categorias de certificado",

  /** PARVAL CLIENT ACCOUNT MSG*/
  com_parval_label_clientaccount_error:
    "Error en proceso de obtener el listado de cuentas del cliente",
  com_parval_label_clientaccount_error2:
    "No existen cuentas asociadas al cliente, cliente no existe",

  /** PARVAL CLIENT PROFILE MSG*/
  com_parval_label_clientprofile_error:
    "Error en proceso de obtener los datos del perfil cliente",
  com_parval_label_clientprofile_error2:
    "No existen perfil asociado al cliente, cliente no existe",

  /** PAYMENT ERROR MSG*/
  com_parval_label_payment_auth_error:
    "Error en proceso previo para autorización de pago",
  com_parval_label_payment_auth_enrollment_error:
    "Error en proceso previo para inscripción de pago",
  com_parval_label_payment_auth_transaction_id_error:
    "Error en proceso de obtener el id de la transacción",
  com_parval_label_payment_complete_error: "Error en proceso de completar pago",

  /** MANAGEMENT SUCCESS MSG*/
  /** MANAGEMENT ERROR MSG */
  com_parval_label_management_find_error: "Error en consulta de gestiones",
  com_parval_label_management_findid_error:
    "Error en consulta por id de gestión",
  com_parval_label_management_save_error: "Error en proceso de guardar gestión",
  com_parval_label_management_update_error:
    "Error en proceso de actualizar gestión",
  com_parval_label_management_delete_error:
    "Error en proceso de eliminar gestión",
}
