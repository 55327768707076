import { URL } from "../../utils/UrlConstant";
import { publicFetch } from "../../utils/CustomFetch";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";
import qs from "qs";
import { Buffer } from "buffer";
import UrlBase from "../../urls/Urls";

export const SVAPPloginRequest = async () => {
  let responseData = {};

  //auth header data
  const appClientId = process.env.REACT_APP_SECURITY_APPCLIENT_ID;
  const appClientPass = process.env.REACT_APP_SECURITY_APPCLIENT_PASS;
  let encodedAuth = Buffer.from(
    `${appClientId}:${appClientPass}`,
    "binary"
  ).toString("base64");
  //auth body data
  const requestBody = {
    username: process.env.REACT_APP_SECURITY_APPUSER_ID,
    password: process.env.REACT_APP_SECURITY_APPUSER_PASS,
    grant_type: CONSTANT.AUTH_GRANTTYPE,
  };

  await publicFetch
    .post(URL.APPLOGIN_REQUEST, /* qs.stringify(requestBody) */ "", {
      headers: {
        ...CONSTANT.HEADER_TYPE,
        Authorization: "Basic " + encodedAuth,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_applogin_error);
    });

  return responseData;
};

export const SVloginCodeRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    username: data.email,
    password: data.password,
    userPortal: "Publico",
  };
  await publicFetch
    .post(
      createUrl(URL.LOGIN_TWOFACTORCODE_REQUEST, data.portalSelect),
      JSON.stringify(requestBody),
      {
        headers: { ...CONSTANT.HEADER_TYPE2 },
      }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      if (error?.response) {
        let data = await error.response?.data;
        if (data?.code === "001") {
          throw new Error(properties.com_parval_label_login_error);
        }
        if (data?.code === "002") {
          throw new Error(properties.com_parval_label_login_error2);
        }
        if (data?.code === "005") {
          throw new Error(properties.com_parval_label_login_error3);
        }
      }
      throw new Error(properties.com_parval_label_login_twofactor_error);
    });

  return responseData;
};

export const SVloginRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    username: data.email,
    password: data.password,
    code: data.code,
    userPortal: "Publico",
  };
  await publicFetch
    .post(
      createUrl(URL.LOGIN_REQUEST, data.portalSelect),
      JSON.stringify(requestBody),
      {
        headers: { ...CONSTANT.HEADER_TYPE2 },
      }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 401 ||
          (error.response.status === 500 && error.response.data?.message))
      ) {
        throw new Error(properties.com_parval_label_login_error);
      }
      throw new Error(properties.com_parval_label_login_general_error);
    });

  return responseData;
};

export const SVRecoveryPassRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    username: data.email,
  };
  await publicFetch
    .post(
      createUrl(URL.LOGIN_RECOVERY_PASS_REQUEST, data.portalSelect),
      JSON.stringify(requestBody),
      {
        headers: { ...CONSTANT.HEADER_TYPE2 },
      }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_recoverypass_general_error);
    });

  return responseData;
};

export const SVChangePassRequest = async (data, portal) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    password: data.password,
  };
  await publicFetch
    .put(
      createUrl(URL.LOGIN_CHANGE_PASS_REQUEST, portal),
      JSON.stringify(requestBody),
      {
        headers: { ...CONSTANT.HEADER_TYPE2 },
      }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_forgotpass_general_error);
    });

  return responseData;
};

export const SVActiveUserRequest = async (portal, email, code) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["username", email],
    ["code", code],
  ]);
  await publicFetch
    .get(createUrl(URL.LOGIN_ACTIVE_USER_REQUEST, portal), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_activeregister_general_error);
    });

  return responseData;
};

const createUrl = (url, portal) => {
  let customUrl = "";
  switch (portal) {
    case CONSTANT.PORTAL_DD:
      customUrl = UrlBase.dd_base_url + process.env.REACT_APP_API_CONTEXT + url;
      break;
    case CONSTANT.PORTAL_OP:
      customUrl = UrlBase.op_base_url + process.env.REACT_APP_API_CONTEXT + url;
      break;
    case CONSTANT.PORTAL_SM:
      customUrl = UrlBase.sm_base_url + process.env.REACT_APP_API_CONTEXT + url;
      break;
    default:
      customUrl = UrlBase.base_url + process.env.REACT_APP_API_CONTEXT + url;
      break;
  }
  return customUrl;
};
