import localforage from "localforage"
import jwt_decode from "jwt-decode"
import { SVAPPloginRequest } from "../services/security/SecurityService"
/**
 * Set Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const setAuthData = async (keyName, authData) => {
  try {
    await setItem(keyName, authData)
    const value = await getItem(keyName)
    return JSON.parse(value)
  } catch (err) {
    return authData
  }
}

/**
 * Get Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const getAuthData = async () => {
  let authData = null
  try {
    authData = await getItem("authInfo")
    //validate authData
    if (authData) {
      const decodeToken = jwt_decode(authData?.access_token)
      const expiresAt = decodeToken?.exp
      let valid = new Date().getTime() / 1000 < expiresAt
      if (valid) {
        return authData
      }
    }
    //obtener token
    let tokenData = await SVAPPloginRequest()
    //set token in localstorage
    authData = await setAuthData("authInfo", tokenData)
  } catch (error) {
    console.log(error)
  }
  return authData
}

export const setItem = async (key, value) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de informacion")
  })
}

export const getItem = async (key) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de informacion")
  })
}

export const removeItem = async (key) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la informacion")
  })
}
