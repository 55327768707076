//Urls Base
const UrlBase = {
  base_url: window.REACT_APP_BASE_URL,
  op_base_url: window.REACT_APP_OP_BASE_URL,
  op_session_base_url: window.REACT_APP_OP_SESSION_BASE_URL,
  dd_base_url: window.REACT_APP_DD_BASE_URL,
  dd_session_base_url: window.REACT_APP_DD_SESSION_BASE_URL,
  sm_base_url: window.REACT_APP_SM_BASE_URL,
  sm_session_base_url: window.REACT_APP_SM_SESSION_BASE_URL,
  parval_external_api_base_url: window.REACT_APP_PARVAL_EXTERNAL_API_BASE_URL,
  parval_external_api_context: window.REACT_APP_PARVAL_EXTERNAL_API_CONTEXT,
  parval_external_authorization: window.REACT_APP_PARVAL_EXTERNAL_AUTHORIZATION,
  parval_external_return_url: window.REACT_APP_PARVAL_EXTERNAL_RETURN_URL,
  registration_x_page: window.REACT_APP_REGISTRATION_X_PAGE,
  modal_registration_x_page: window.REACT_APP_MODAL_REGISTRATION_X_PAGE,
  certificate_registration_x_page:
    window.REACT_APP_CERTIFICATE_REGISTRATION_X_PAGE,
  parval_cs_payer_url: window.REACT_APP_PARVAL_CS_PAYER_URL,
};

export default {
  //Url's de inicio y cierre de sesión
  base_url: `${UrlBase.base_url}`,
  op_base_url: `${UrlBase.op_base_url}`,
  op_session_base_url: `${UrlBase.op_session_base_url}`,
  dd_base_url: `${UrlBase.dd_base_url}`,
  dd_session_base_url: `${UrlBase.dd_session_base_url}`,
  sm_base_url: `${UrlBase.sm_base_url}`,
  sm_session_base_url: `${UrlBase.sm_session_base_url}`,
  parval_external_api_base_url: `${UrlBase.parval_external_api_base_url}`,
  parval_external_api_context: `${UrlBase.parval_external_api_context}`,
  parval_external_authorization: `${UrlBase.parval_external_authorization}`,
  parval_external_return_url: `${UrlBase.parval_external_return_url}`,
  registration_x_page: `${UrlBase.registration_x_page}`,
  modal_registration_x_page: `${UrlBase.modal_registration_x_page}`,
  certificate_registration_x_page: `${UrlBase.certificate_registration_x_page}`,
  parval_cs_payer_url: `${UrlBase.parval_cs_payer_url}`,
};
