import axios from "axios";
import { getItem } from "./LocalStorageManager";
import jwt_decode from "jwt-decode";
import UrlBase from "../urls/Urls.js";
/**
 * Axion public
 */
const publicFetch = axios.create({
  baseURL: UrlBase.base_url + process.env.REACT_APP_API_CONTEXT,
});

/**
 * Axion private - internal parval endpoints
 */
const privateFetch = axios.create({
  baseURL: UrlBase.base_url + process.env.REACT_APP_API_CONTEXT,
});

/**
 * Axion private - interceptors
 */
privateFetch.interceptors.request.use(async (config) => {
  //validate uri to send token
  const { origin } = new URL(config.baseURL + config.url);
  const allowedOrigins = [UrlBase.base_url];
  const authInfo = await getItem("authInfo");
  const decodeToken = jwt_decode(authInfo?.access_token);
  const expiresAt = decodeToken.exp;
  let valid = new Date().getTime() / 1000 < expiresAt;
  if (allowedOrigins.includes(origin) && valid) {
    config.headers.Authorization = `Bearer ${authInfo?.access_token}`;
  }
  return config;
});

/**
 * Axion private external- external parval endpoints
 */
const privateExternalFetch = axios.create({
  baseURL:
    UrlBase.parval_external_api_base_url +
    process.env.REACT_APP_PARVAL_EXTERNAL_API_CONTEXT,
});

/**
 * Axion private external- interceptors
 */
privateExternalFetch.interceptors.request.use(async (config) => {
  //validate uri to send token
  const { origin } = new URL(config.baseURL + config.url);
  const allowedOrigins = [UrlBase.parval_external_api_base_url];
  if (allowedOrigins.includes(origin)) {
    config.headers.Authorization =
      process.env.REACT_APP_PARVAL_EXTERNAL_AUTHORIZATION;
  }
  return config;
});

/**
 * Axion private - internal parval endpoints
 */
const privateFetch2 = axios.create({
  baseURL: "http://localhost:4040/v1",
});

/**
 * Axion private - interceptors
 */
privateFetch2.interceptors.request.use(async (config) => {
  //validate uri to send token
  const authInfo = await getItem("authInfo");
  const decodeToken = jwt_decode(authInfo?.access_token);
  const expiresAt = decodeToken.exp;
  let valid = new Date().getTime() / 1000 < expiresAt;
  if (valid) {
    config.headers.Authorization = `Bearer ${authInfo?.access_token}`;
  }
  return config;
});

export { publicFetch, privateFetch, privateFetch2, privateExternalFetch };
